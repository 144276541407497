import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Alert } from "antd";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import { authenticationService } from "../../common/auth";
import styles from "./styles.module.less";

export const Login = () => {
  const { t } = useTranslation("login");
  const history = useHistory();
  const [failed, setFailed] = useState(false);

  const turnstile = useTurnstile();
  const [turnstileToken, setTurnstileToken] = useState("");

  const handleOk = (values: any) => {
    authenticationService
      .login(turnstileToken, values.login, values.password)
      .then(() => history.push("/"))
      .catch(() => setFailed(true));
  };

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo192.png" />
          <span>{t("Dashboard")}</span>
        </div>

        <Alert
          type="info"
          style={{ marginBottom: "24px" }}
          message={
            <>
              {t("Try the renewed dashboard here")}
              <br />
              <a href="https://my-new.ltesocks.io" target="_blank" rel="noopener noreferrer">
                https://my-new.ltesocks.io
              </a>
            </>
          }
        />

        <Form initialValues={{ remember: true }} onFinish={handleOk}>
          {failed && <p>{t("Login failed")}</p>}

          <Form.Item name="login" rules={[{ required: true }]}>
            <Input prefix={<UserOutlined />} placeholder={t("Login")} />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true }]}>
            <Input type="password" prefix={<LockOutlined />} placeholder={t("Password")} />
          </Form.Item>

          <Turnstile sitekey="0x4AAAAAAA1URTx6qE9dY6tz" onVerify={(token) => setTurnstileToken(token)} />
          <br />

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={turnstileToken === ""}>
              {t("Sign In")}
            </Button>
          </Form.Item>

          <Link to={"/register"}>
            <Button>{t("Sign Up")}</Button>
          </Link>
        </Form>
      </div>
    </Fragment>
  );
};
